import React, { useState } from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ArchitecturalArray } from './../../utils/Gallery/Catagories'
import Carousel from 'react-bootstrap/Carousel'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Next, Prev } from 'react-bootstrap/esm/PageItem';
console.log("Architectural hey..", ArchitecturalArray)
// import img2 from "../../assets/images/carousal-slide/002.jpg";
// import img1 from "../../assets/images/carousal-slide/001.jpg";
// import img3 from "../../assets/images/carousal-slide/003.jpg";
// import img4 from "../../assets/images/carousal-slide/004.jpg";
// import img5 from "../../assets/images/carousal-slide/005.jpg";
// import img6 from "../../assets/images/carousal-slide/006.jpg";
// import img7 from "../../assets/images/carousal-slide/007.jpg";
// import img8 from "../../assets/images/carousal-slide/008.jpg";

const Architecturalvisualiztion = () => {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setActiveIndex(selectedIndex);
    };

    const popOfModal = (e, image) => {
        e.preventDefault();
        let activeIndex = ArchitecturalArray?.indexOf(image);
        setActiveIndex(activeIndex);
        setIsOpenModal(true);
    }

    return (
        <div>
            {isOpenModal && (
                <Modal fade={true} isOpen={isOpenModal} toggle={(e) => { setIsOpenModal(false) }} centered={true}
                    className="gallery_modal_slider"
                    size="xl">
                    {/* <ModalHeader className='border-0' toggle={(e) => { setIsOpenModal(false) }}></ModalHeader> */}
                    <Carousel interval={null} activeIndex={activeIndex} onSelect={handleSelect} wrap={true}
                        keyboard={true} onKeyUp={Next} onKeyDown={Prev}>
                        {ArchitecturalArray?.map((image) => {
                            return (
                                <Carousel.Item>
                                      <LazyLoadImage className='cursor-pointer md:h-[400px] w-full' src={image} alt="" />
                                    {/* <img
                                        className="d-block w-100 h-full"
                                        src={image}
                                        alt="First slide"
                                    /> */}
                                </Carousel.Item>
                            )
                        })}
                    </Carousel>
                </Modal>
            )}
            <div className="content max-w-7xl mx-auto my-16 px-3">
                <h2 className='md:text-5xl text-center text-4xl font-bold px-4 mb-9 after:content-[""] after:h-[2px] after:bg-orange-500 after:my-10 after:mx-auto after:w-[100px] after:block'>Architectural Visualization</h2>
                <p className=' text-center max-w-4xl mx-auto text-xl text-gray-500 font-medium'>Architects and construction companies are able to receive a high-quality and emotionally&#8209;appealing&nbsp;visualization from us even before the completion of their objects, helping them to stand out from their competition and to market more successfully.</p>
            </div>
            <div className='exterior'>
                <div className=' grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3'>
                    {ArchitecturalArray.length > 0 && ArchitecturalArray?.map((image, index) => {
                        return (
                            <div key={index} className='p-2'>
                                <div onClick={(e) => {
                                        popOfModal(e, image);
                                    }} className="wrap relative">
                                    <img src={image} alt="" />
                                    <div class="caption">

                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    {/* <div className='p-2'>
                        <div className="wrap relative">
                            <img src={img1} alt="" />
                            <div class="caption">

                            </div>
                        </div>
                    </div>
                    <div className='p-2'>
                        <div className="wrap relative">
                            <img src={img2} alt="" />
                            <div class="caption">

                            </div>
                        </div>
                    </div>
                    <div className='p-2'>
                        <div className="wrap relative">
                            <img src={img3} alt="" />
                            <div class="caption">

                            </div>
                        </div>
                    </div>
                    <div className='p-2'>
                        <div className="wrap relative">
                            <img src={img4} alt="" />
                            <div class="caption">

                            </div>
                        </div>
                    </div>
                    <div className='p-2'>
                        <div className="wrap relative">
                            <img src={img5} alt="" />
                            <div class="caption">

                            </div>
                        </div>
                    </div>
                    <div className='p-2'>
                        <div className="wrap relative">
                            <img src={img6} alt="" />
                            <div class="caption">

                            </div>
                        </div>
                    </div>
                    <div className='p-2'>
                        <div className="wrap relative">
                            <img src={img7} alt="" />
                            <div class="caption">

                            </div>
                        </div>
                    </div>
                    <div className='p-2'>
                        <div className="wrap relative">
                            <img src={img8} alt="" />
                            <div class="caption">

                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Architecturalvisualiztion
