import image1 from "../../../../src/assets/images/Catagories/Bathroom/bathroom1.jpg";
import image2 from "../../../../src/assets/images/Catagories/Bathroom/bathroom2.jpg";
import image3 from "../../../../src/assets/images/Catagories/Bathroom/bathroom3.jpg";
import image4 from "../../../../src/assets/images/Catagories/Bathroom/bathroom4.jpg";
import image5 from "../../../../src/assets/images/Catagories/Bathroom/bathroom5.jpg";
import image6 from "../../../../src/assets/images/Catagories/Bathroom/bathroom6.jpg";
import image7 from "../../../../src/assets/images/Catagories/Bathroom/bathroom7.jpg";

const BathRoom = [image1, image2, image3, image4, image5, image6];
export const BathRoomArray = [];

const chunkSize = 3;
const chunkImage = Math.floor(BathRoom.length/chunkSize);

for (let i = 0; i < BathRoom.length; i += chunkImage) {
    const chunk = BathRoom.slice(i, i + chunkImage);
    BathRoomArray.push(chunk);
}