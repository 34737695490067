import React,{ useState, useEffect} from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";
import Carousel from 'react-bootstrap/Carousel'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Next, Prev } from 'react-bootstrap/esm/PageItem';

const InteriorvisualizationTabs = ({ images, title }) => {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [arrayImages, setArrayImages] = useState([]);
    console.log("arrayImages",arrayImages)
    // console.log("title", title)
    // console.log("images", images)

    useEffect(()=>{
        let arrayFlat = images?.flat();
        setArrayImages(arrayFlat);
        console.log("arrayFlat",arrayFlat)
    },[images])
    let data = 15 / 3
    // console.log("data", Math.floor(data))
    const handleSelect = (selectedIndex, e) => {
        setActiveIndex(selectedIndex);
    };

    const popOfModal = (e, image) => {
        e.preventDefault();
        console.log("image", image)
        let activeIndex = arrayImages?.indexOf(image);
        console.log("activeIndex", activeIndex)
        setActiveIndex(activeIndex);
        setIsOpenModal(true);
    }
    return (
        <div className="pt-8">
              {isOpenModal && (
                <Modal fade={true} isOpen={isOpenModal} toggle={(e) => { setIsOpenModal(false) }} centered={true}
                    className="gallery_modal_slider"
                    size="xl">
                    {/* <ModalHeader className='border-0' toggle={(e) => { setIsOpenModal(false) }}></ModalHeader> */}
                    <Carousel interval={null} activeIndex={activeIndex} onSelect={handleSelect} wrap={true}
                        keyboard={true} onKeyUp={Next} onKeyDown={Prev}>
                        {arrayImages?.map((image) => {
                            return (
                                <Carousel.Item>
                                    <LazyLoadImage className='cursor-pointer d-block h-full' src={image} alt="" />
                                    {/* <img
                                        className="d-block w-100 h-full"
                                        src={image}
                                        alt="First slide"
                                    /> */}
                                </Carousel.Item>
                            )
                        })}
                    </Carousel>
                </Modal>
            )}
            <h2 className='md:text-3xl text-center text-2xl font-bold p-4 '>{title}</h2>
            <div className='interior md:flex'>
                {images && images?.map((image) => {
                    return (
                        <div className="flex flex-column lg:w-4/12 w-full ">
                            {image && image.map((column) => {
                                return (
                                    <div className='p-2'>
                                        <div onClick={(e) => {
                                        popOfModal(e, column);
                                    }} className="wrap relative">
                                            <img className='' src={column} alt="" />
                                            <div class="caption">
                                                <h1></h1>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default InteriorvisualizationTabs
