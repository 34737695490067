import image1 from "../../../../src/assets/images/Catagories/LivingRoom/LivingRoom1.jpg";
import image2 from "../../../../src/assets/images/Catagories/LivingRoom/LivingRoom2.jpg";
import image3 from "../../../../src/assets/images/Catagories/LivingRoom/LivingRoom3.jpg";
import image4 from "../../../../src/assets/images/Catagories/LivingRoom/LivingRoom4.jpg";
import image5 from "../../../../src/assets/images/Catagories/LivingRoom/LivingRoom5.jpg";

import image6 from "../../../../src/assets/images/Catagories/LivingRoom/LivingRoom6.jpg";
import image7 from "../../../../src/assets/images/Catagories/LivingRoom/LivingRoom7.jpg";
import image9 from "../../../../src/assets/images/Catagories/LivingRoom/LivingRoom8.jpg";
import image10 from "../../../../src/assets/images/Catagories/LivingRoom/LivingRoom9.jpg";
import image11 from "../../../../src/assets/images/Catagories/LivingRoom/LivingRoom10.jpg";
import image12 from "../../../../src/assets/images/Catagories/LivingRoom/LivingRoom11.jpg";
import image13 from "../../../../src/assets/images/Catagories/LivingRoom/LivingRoom12.jpg";
import image14 from "../../../../src/assets/images/Catagories/LivingRoom/LivingRoom13.jpg";

import image15 from "../../../../src/assets/images/Catagories/LivingRoom/LivingRoom14.jpg";
import image16 from "../../../../src/assets/images/Catagories/LivingRoom/LivingRoom15.jpg";
import image17 from "../../../../src/assets/images/Catagories/LivingRoom/LivingRoom16.jpg";

const LivingRoom = [image1, image2, image3, image4, image5, image6, image7, image9, image10, image11, image12, image13, image14, 
    image15, image16];
export const LivingRoomArray = [];
console.log(LivingRoom.length)
const chunkSize = 3;
const chunkImage = Math.floor(LivingRoom.length/chunkSize);

for (let i = 0; i < LivingRoom.length; i += chunkImage) {
    const chunk = LivingRoom.slice(i, i + chunkImage);
    LivingRoomArray.push(chunk);
}