import React, { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import AnimatedText from 'react-animated-text-content';
// import Carousel from 'react-bootstrap/Carousel';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import img1 from "../../assets/images/carousal-slide/001.jpg";
import img2 from "../../assets/images/carousal-slide/002.jpg";
import img3 from "../../assets/images/carousal-slide/003.jpg";
import img4 from "../../assets/images/carousal-slide/004.jpg";
import img5 from "../../assets/images/carousal-slide/005.jpg";
import img6 from "../../assets/images/carousal-slide/006.jpg";
import img7 from "../../assets/images/carousal-slide/007.jpg";
import img8 from "../../assets/images/carousal-slide/008.jpg";

const Carousal = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplaySpeed: 3000,
    autoplay: true,
    speed: 1500,
    fade: true,
    cssEase: 'linear',
  };
  return (
    <>

         <div>
        <Slider {...settings} className="py-4 lg:py-8 md:px-6 lg:px-8 w-full ">
          <div className="item relative">
            <img className='w-full h-full animate-scale-up xl:h-[50vw]' src={img1} alt="" />
            <div className="overlay"></div>
            <div className='slider-Content absolute animate-example text-white top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'>
              <h5 className="text-[5vw] md:text-[4vw] lg:text-[3vw] font-bold text-center ">
                Professional 3D Rendering and Animation
              </h5>
              <p className="text-center text-[3.25vw] md:text-[2.25vw] lg:text-[1.25vw] font-bold pt-4">
                Allow your customers to feel like they’re entering their new home
              </p>
            </div>
          </div>
          <div className="item relative">
            <img className='w-full h-full animate-scale-up xl:h-[50vw]' src={img2} alt="" />
            <div className="overlay"></div>
            <div className='slider-Content absolute animate-example text-white top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'>
              <h5 className="text-[5vw] md:text-[4vw] lg:text-[3vw] font-bold text-center ">
                Photorealistic 3D Renderings
              </h5>
              <p className="text-center text-[3.25vw] md:text-[2.25vw] lg:text-[1.25vw] font-bold pt-4">
                High-quality CGIs that look like real photography
              </p>


            </div>
          </div>
          <div className="item relative">
            <img className='w-full h-full animate-scale-up xl:h-[50vw]' src={img3} alt="" />
            <div className="overlay"></div>
            <div className='slider-Content absolute animate-example text-white top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'>
              <h5 className="text-[5vw] md:text-[4vw] lg:text-[3vw] font-bold text-center ">
                Emotional Interior Visualization
              </h5>
              <p className="text-center text-[3.25vw] md:text-[2.25vw] lg:text-[1.25vw] font-bold pt-4">
                Offer compelling images of atmospheric interior design
              </p>

            </div>
          </div>
          <div className="item relative">
            <img className='w-full h-full animate-scale-up xl:h-[50vw]' src={img4} alt="" />
            <div className="overlay"></div>
            <div className='slider-Content absolute animate-example text-white top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'>

              <h5 className="text-[5vw] md:text-[4vw] lg:text-[3vw] font-bold text-center ">
                Photorealistic Product Visualization
              </h5>
              <p className="text-center text-[3.25vw] md:text-[2.25vw] lg:text-[1.25vw] font-bold pt-4">
                Present your creations in a completely new way
              </p>
            </div>

          </div>
          <div className="item relative">
            <img className='w-full h-full animate-scale-up xl:h-[50vw]' src={img5} alt="" />
            <div className="overlay"></div>
            <div className='slider-Content absolute animate-example text-white top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'>
              <h5 className="text-[5vw] md:text-[4vw] lg:text-[3vw] font-bold text-center ">
                Urban Visions in 3D
              </h5>
              <p className="text-center text-[3.25vw] md:text-[2.25vw] lg:text-[1.25vw] font-bold pt-4">
                Enjoy a high-end depiction of your building project
              </p>

            </div>

          </div>
          <div className="item relative">
            <img className='w-full h-full animate-scale-up xl:h-[50vw]' src={img6} alt="" />
            <div className="overlay"></div>
            <div className='slider-Content absolute animate-example text-white top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'>

              <h5 className="text-[5vw] md:text-[4vw] lg:text-[3vw] font-bold text-center ">
                High-end Interior Designs
              </h5>
              <p className="text-center text-[3.25vw] md:text-[2.25vw] lg:text-[1.25vw] font-bold pt-4">
                Let your clients get a taste of their future property
              </p>
            </div>

          </div>
          <div className="item relative">
            <img className='w-full h-full animate-scale-up xl:h-[50vw]' src={img7} alt="" />
            <div className="overlay"></div>
            <div className='slider-Content absolute animate-example text-white top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'>

              <h5 className="text-[5vw] md:text-[4vw] lg:text-[3vw] font-bold text-center ">
                High-end Interior Designs
              </h5>
              <p className="text-center text-[3.25vw] md:text-[2.25vw] lg:text-[1.25vw] font-bold pt-4">
                Let your clients get a taste of their future property
              </p>
            </div>

          </div>
          <div className="item relative">
            <img className='w-full h-full animate-scale-up xl:h-[50vw]' src={img8} alt="" />
            <div className="overlay"></div>
            <div className='slider-Content absolute animate-example text-white top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]'>
              <h5 className="text-[5vw] md:text-[4vw] lg:text-[3vw] font-bold text-center ">
                High-end Interior Designs
              </h5>
              <p className="text-center text-[3.25vw] md:text-[2.25vw] lg:text-[1.25vw] font-bold pt-4">
                Let your clients get a taste of their future property
              </p>

            </div>

          </div>

          {/* <div className="relative">
            <img className='w-full h-[800px]' src={img9} alt="" />
          
          </div>
          <div className="relative">
            <img className='w-full h-[800px]' src={img10} alt="" />
          
          </div>
          <div className="relative">
            <img className='w-full h-[800px]' src={img11} alt="" />
          
          </div>
          <div className="relative">
            <img className='w-full h-[800px]' src={img12} alt="" />
          
          </div>
          <div className="relative">
            <img className='w-full h-[800px]' src={img13} alt="" />
          
          </div>
          <div className="relative">
            <img className='w-full h-[800px]' src={img14} alt="" />
          
</div> */}


        </Slider>
      </div>
    </>
  );
}

export default Carousal;