import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
} from "react-router-dom";
import website_icon from "../../assets/images/icon/TM-ViZ-STUDIO.png";


const Header = () => {
    const [navbar, setNavbar] = useState(false);
    const [activeTab, setActiveTab] = useState(1);
const history = useNavigate();

    const scrollToBottom = () => {
        window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });

    }
    return (
        <div className="header-top">
            <nav className="header-nav w-full lg:shadow">
                <div className="first-nav-bar lg:border-0 border-b justify-between mx-auto lg:items-center lg:flex lg:px-8">
                    <div>
                        <div className="flex items-center lg:px-0 px-4 justify-between lg:block">
                            <a>
                                {/* <h2 className="text-2xl font-bold text-black">LOGO</h2> */}
                                <img
                                onClick={()=>history('/')}
                                    src={website_icon}
                                    className="text-2xl font-bold text-black icon-image md:w-[70px] w-[50px] md:h-[70px] h-[50px]"
                                    alt="..."
                                />
                            </a>
                            <div className="lg:hidden flex items-center">
                                <div className="space-x-2 pr-3 ">
                                    <Link
                                        to="/"
                                        className="sm:px-4 px-1 sm:py-2 py-1 text-gray-50 bg-orange-400 rounded-2xl sm:text-base text-sm shadow hover:text-slate-800 cursor-pointer"
                                    >
                                        Contact US
                                    </Link>
                                </div>
                                <button
                                    className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                                    onClick={() => setNavbar(!navbar)}
                                >
                                    {navbar ? (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-6 h-6 text-black"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    ) : (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-6 h-6 text-black"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M4 6h16M4 12h16M4 18h16"
                                            />
                                        </svg>
                                    )}
                                </button>

                            </div>
                        </div>
                    </div>
                    <div>

                        <div
                            className={`block services justify-self-center py-8 px-8 lg:px-0 lg:py-0 bg-white lg:bg-transparent lg:flex lg:flex-1 lg:h-0 ${navbar ? "block" : "hidden"
                                }`}>
                            <ul className="items-center justify-center space-y-8 lg:flex lg:space-y-0 lg:space-x-6">
                                <li className="text-black cursor-pointer">
                                    <Link exact to="/" className={`li-a-font-size ${activeTab == 1 && "active"}`} onClick={()=>setActiveTab(1)}>Home</Link>
                                </li>
                                <li className="text-black cursor-pointer">
                                    <Link to="/architecturalvisualiztion" className={`li-a-font-size ${activeTab == 2 && "active"}`} onClick={()=>setActiveTab(2)}>Architectural Visualization</Link>
                                </li>
                                <li className="text-black cursor-pointer">
                                    <Link to="/interiorvisualization" className={`li-a-font-size ${activeTab == 3 && "active"}`} onClick={()=>setActiveTab(3)}>Interior Visualization</Link>
                                </li>
                                <li className="text-black cursor-pointer">
                                    <Link to="/animation" className={`li-a-font-size ${activeTab == 4 && "active"}`} onClick={()=>setActiveTab(4)}>3D Animation</Link>
                                </li>
                                <li className="text-black cursor-pointer">
                                    <Link to="/aboutus" className={`li-a-font-size ${activeTab == 5 && "active"}`} onClick={()=>setActiveTab(5)}>About Us</Link>
                                </li>

                            </ul>

                            <div className="mt-3 space-y-2 hidden md:inline-block">
                            </div>
                        </div>
                    </div >
                    <div className="space-x-2 hidden lg:inline ">
                        <a href="#contact-us"

                            className="px-3 py-2 text-gray-50 bg-orange-400 rounded-2xl shadow hover:text-slate-800 cursor-pointer"
                        >
                            Contact US
                        </a>
                    </div>
                </div >

                {/* <div className="nav-bar-top-border hidden justify-center px-4 mx-auto lg:items-center lg:flex md:px-8">
                    <div>
                        <div className="flex items-center justify-between py-3 md:py-5 md:block">
                            <a href="javascript:void(0)">
                                <h2 className="text-2xl font-bold text-black"></h2>
                            </a>
                            <div className="md:hidden">
                                <button
                                    className="p-2 text-gray-700 rounded-md outline-none focus:border-gray-400 focus:border"
                                    onClick={() => setNavbar(!navbar)}
                                >
                                    {navbar ? (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-6 h-6 text-black"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                                clipRule="evenodd"
                                            />
                                        </svg>
                                    ) : (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="w-6 h-6 text-black"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                            strokeWidth={2}
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                d="M4 6h16M4 12h16M4 18h16"
                                            />
                                        </svg>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div
                            className={`flex-1 justify-self-center pb-3 mt-8 md:block md:pb-0 md:mt-0 ${navbar ? "block" : ""
                                }`}
                        >
                            <ul className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0">
                                <li className="text-black cursor-pointer">
                                    <a className="li-a-font-size">All CGIs</a>
                                </li>
                                <li className="text-black cursor-pointer">
                                    <a className="li-a-font-size">Architectural Visualization</a>
                                </li>
                                <li className="text-black cursor-pointer">
                                    <a className="li-a-font-size" >Interior Visualization</a>
                                </li>
                                {/* <li className="text-black cursor-pointer">
                                    <a className="li-a-font-size" >3D Product Rendering</a>
                                </li> 
                                <li className="text-black cursor-pointer">
                                    <a className="li-a-font-size" >3D Animation</a>
                                </li>
                                <li className="text-black cursor-pointer">
                                    <a className="li-a-font-size" >Apartment selector</a>
                                </li>

                            </ul>

                            <div className="mt-3 space-y-2 lg:hidden md:inline-block">
                                <a
                                href="javascript:void(0)"
                                className="inline-block w-full px-4 py-2 text-center text-black bg-gray-600 rounded-md shadow hover:bg-gray-800"
                            >
                                Sign in
                            </a>
                                <a
                                    href="javascript:void(0)"
                                    className="inline-block w-full px-4 py-2 text-center text-gray-800 rounded-md shadow hover:bg-gray-100"
                                >

                                </a>
                            </div>
                        </div>
                    </div>
                </div> */}

            </nav >
        </div >


    );
}

export default Header;