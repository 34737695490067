import image2 from "../../../../src/assets/images/Catagories/FoyerArea/FoyerArea1.jpg";
import image3 from "../../../../src/assets/images/Catagories/FoyerArea/FoyerArea2.jpg";
import image4 from "../../../../src/assets/images/Catagories/FoyerArea/FoyerArea3.jpg";
import image5 from "../../../../src/assets/images/Catagories/FoyerArea/FoyerArea4.jpg";
import image6 from "../../../../src/assets/images/Catagories/FoyerArea/FoyerArea5.jpg";
import image7 from "../../../../src/assets/images/Catagories/FoyerArea/FoyerArea6.jpg";
import image8 from "../../../../src/assets/images/Catagories/FoyerArea/FoyerArea7.jpg";
import image9 from "../../../../src/assets/images/Catagories/FoyerArea/FoyerArea8.jpg";
import image10 from "../../../../src/assets/images/Catagories/FoyerArea/FoyerArea9.jpg";
import image11 from "../../../../src/assets/images/Catagories/FoyerArea/FoyerArea10.jpg";
import image12 from "../../../../src/assets/images/Catagories/FoyerArea/FoyerArea11.jpg";
import image13 from "../../../../src/assets/images/Catagories/FoyerArea/FoyerArea12.jpg";
import image14 from "../../../../src/assets/images/Catagories/FoyerArea/FoyerArea13.jpg";

const FoyerArea = [image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12, image13];
export const FoyerAreaArray = [];

const chunkSize = 3;
const chunkImage = Math.floor(FoyerArea.length/chunkSize);

for (let i = 0; i < FoyerArea.length; i += chunkImage) {
    const chunk = FoyerArea.slice(i, i + chunkImage);
    FoyerAreaArray.push(chunk);
}