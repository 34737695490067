import React from 'react';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toast'
import './index.css';
import "./libcss";
import Routes from "./routes/index";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Routes />
    <ToastContainer delay={5000} />
  </React.StrictMode>
);

