import React,{useState} from 'react';
import img1 from "../../assets/images/addMore/img1.jpg";
import img2 from "../../assets/images/addMore/img2.jpg";
import img3 from "../../assets/images/addMore/img3.jpg";
import img4 from "../../assets/images/addMore/img4.jpg";
import img5 from "../../assets/images/addMore/img5.jpg";
import img6 from "../../assets/images/addMore/img6.jpg";
import img7 from "../../assets/images/addMore/img7.jpg";
import img8 from "../../assets/images/addMore/img8.jpg";
import img9 from "../../assets/images/addMore/img9.jpg";
import img10 from "../../assets/images/addMore/img10.jpg";
import img11 from "../../assets/images/addMore/img11.jpg";
import img12 from "../../assets/images/addMore/img12.jpg";
import { BathRoomArray, BedRoomArray, CutSectionArray, DiningAndKitchenArray, FoyerAreaArray, KidsRoomArray, LivingRoomArray, OfficeAndShopDesignArray } from "../../utils/Gallery/Catagories/index";
import InteriorvisualizationTabs from "../../component/Sevices/InteriorvisualizationTabs";

const Interiorvisualization = () => {
    const [activeTab, setActiveTab] = useState(1);
    const [title, setTitle] = useState("Living Room");

    return (
        <div>
            <div className="content max-w-7xl mx-auto my-16 px-3">
                <h2 className='md:text-5xl text-center text-4xl font-bold px-4 mb-9 after:content-[""] after:h-[2px] after:bg-orange-500 after:my-10 after:mx-auto after:w-[100px] after:block'>Interior Visualization</h2>
                <p className=' text-center max-w-4xl mx-auto text-xl text-gray-500 font-medium'>Are your clients having difficulties understanding anything from floor plans and construction drawings? By enhancing interiors with our high-quality visualizations, we transform your technical offer into emotional living feelings.</p>
            </div>
            <div className=''>
                <ul className='categories border-b flex overflow-x-auto lg:justify-center mx-2 mb-2'>
                    <li><a className={`px-4 py-3  ${activeTab == 1 && "active"}`} onClick={()=>{
                        setActiveTab(1);
                        setTitle("Living Room");
                    }}>Living&nbsp;Room</a></li>
                    <li><a className={`px-4 py-3  ${activeTab == 2 && "active"}`} onClick={()=>{
                        setActiveTab(2);
                        setTitle("Bed Room");
                    }}>Bed&nbsp;Room</a></li>
                    <li><a className={`px-4 py-3  ${activeTab == 3 && "active"}`} onClick={()=>{
                        setActiveTab(3);
                        setTitle("Dining & kitchen");
                    }}>Dining&nbsp;&&nbsp;kitchen</a></li>
                    <li><a className={`px-4 py-3  ${activeTab == 4 && "active"}`} onClick={()=>{
                        setActiveTab(4);
                        setTitle("Kids Room");
                    }}>Kids&nbsp;Room</a></li>
                    <li><a className={`px-4 py-3  ${activeTab == 5 && "active"}`} onClick={()=>{
                        setActiveTab(5);
                        setTitle("Bathroom");
                    }}>Bathroom</a></li>
                    <li><a className={`px-4 py-3  ${activeTab == 6 && "active"}`} onClick={()=>{
                        setActiveTab(6);
                        setTitle("Office & Shop Design");
                    }}>Office&nbsp;Design</a></li>
                    <li><a className={`px-4 py-3  ${activeTab == 7 && "active"}`} onClick={()=>{
                        setActiveTab(7);
                        setTitle("Foyer Area");
                    }}>Foyer&nbsp;Area</a></li>
                    <li><a className={`px-4 py-3 ${activeTab == 8 && "active"}`} onClick={()=>{
                        setActiveTab(8);
                        setTitle("Cut Section");
                    }}>Cut&nbsp;Section</a></li>
                </ul>
            </div>
            <InteriorvisualizationTabs
             images = {activeTab ==1 ? LivingRoomArray: 
                activeTab ==2 ? BedRoomArray:
                activeTab ==3 ? DiningAndKitchenArray: 
                activeTab ==4 ? KidsRoomArray: 
                activeTab ==5 ? BathRoomArray: 
                activeTab ==6 ? OfficeAndShopDesignArray: 
                activeTab ==7 ? FoyerAreaArray: 
                activeTab ==8 ? CutSectionArray: ""}

                title = {title}
            />
            {/* <div className="pt-8">
            <h2 className='md:text-3xl text-center text-2xl font-bold p-4 '>Living Room</h2>
                <div className='interior md:flex'>
                
                    <div className="flex flex-column">
                        <div className='p-2'>
                            <div className="wrap relative">
                                <img src={img1} alt="" />
                                <div class="caption">
                                    <h1></h1>
                                </div>
                            </div>
                        </div>
                        <div className='p-2'>
                            <div className="wrap relative">
                                <img src={img2} alt="" />
                                <div class="caption">
                                    <h1></h1>
                                </div>
                            </div>
                        </div>
                        <div className='p-2'>
                            <div className="wrap relative">
                                <img src={img3} alt="" />
                                <div class="caption">
                                    <h1></h1>
                                </div>
                            </div>
                        </div>
                        <div className='p-2'>
                            <div className="wrap relative">
                                <img src={img4} alt="" />
                                <div class="caption">
                                    <h1></h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-column">
                        <div className='p-2'>
                            <div className="wrap relative">
                                <img src={img5} alt="" />
                                <div class="caption">
                                    <h1></h1>
                                </div>
                            </div>
                        </div>
                        <div className='p-2'>
                            <div className="wrap relative">
                                <img src={img6} alt="" />
                                <div class="caption">
                                    <h1></h1>
                                </div>
                            </div>
                        </div>
                        <div className='p-2'>
                            <div className="wrap relative">
                                <img src={img7} alt="" />
                                <div class="caption">
                                    <h1></h1>
                                </div>
                            </div>
                        </div>
                        <div className='p-2'>
                            <div className="wrap relative">
                                <img src={img8} alt="" />
                                <div class="caption">
                                    <h1></h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-column">
                        <div className='p-2'>
                            <div className="wrap relative">
                                <img src={img9} alt="" />
                                <div class="caption">
                                    <h1></h1>
                                </div>
                            </div>
                        </div>
                        <div className='p-2'>
                            <div className="wrap relative">
                                <img src={img10} alt="" />
                                <div class="caption">
                                    <h1></h1>
                                </div>
                            </div>
                        </div>
                        <div className='p-2'>
                            <div className="wrap relative">
                                <img src={img11} alt="" />
                                <div class="caption">
                                    <h1></h1>
                                </div>
                            </div>
                        </div>
                        <div className='p-2'>
                            <div className="wrap relative">
                                <img src={img12} alt="" />
                                <div class="caption">
                                    <h1></h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default Interiorvisualization
