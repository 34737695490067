import React from 'react'

const Animation = () => {
  return (
    <div>
      <video width="400" controls className='max-w-5xl w-full m-auto p-8' >
        <source src="" type="video/mp4" />
        <source src="" type="video/ogg" />
      </video>
      <video width="400" controls className='max-w-5xl w-full m-auto p-8' >
        <source src="" type="video/mp4" />
        <source src="" type="video/ogg" />
      </video>
      <video width="400" controls className='max-w-5xl w-full m-auto p-8' >
        <source src="" type="video/mp4" />
        <source src="" type="video/ogg" />
      </video>
      <div className="content max-w-7xl mx-auto my-16 px-2">
                <h2 className='md:text-5xl text-center text-4xl font-bold px-4 mb-9 after:content-[""] after:h-[2px] after:bg-orange-500 after:my-10 after:mx-auto after:w-[100px] after:block'>3d Animation</h2>
                <p className=' text-center max-w-4xl mx-auto text-xl text-gray-500'>Do you think your building project or product should be presented as realistic as possible and touch your clients emotionally? If you’re using 3D product videos or animated tours through your real estate, there is no limit to your clients’ imagination – while you seal the deal.</p>
            </div>
    </div>
    
  )
}

export default Animation
