import { LazyLoadImage } from "react-lazy-load-image-component";
import image1 from "../../../../src/assets/images/Catagories/Architectural/architectural1.jpg";
import image2 from "../../../../src/assets/images/Catagories/Architectural/architectural2.jpg";
import image3 from "../../../../src/assets/images/Catagories/Architectural/architectural3.jpg";
import image4 from "../../../../src/assets/images/Catagories/Architectural/architectural4.jpg";
import image5 from "../../../../src/assets/images/Catagories/Architectural/architectural5.jpg";
import image6 from "../../../../src/assets/images/Catagories/Architectural/architectural6.jpg";
import image7 from "../../../../src/assets/images/Catagories/Architectural/architectural7.jpg";
import image8 from "../../../../src/assets/images/Catagories/Architectural/architectural8.jpg";
import image9 from "../../../../src/assets/images/Catagories/Architectural/architectural9.jpg";
import image10 from "../../../../src/assets/images/Catagories/Architectural/architectural10.jpg";

export const ArchitecturalArray = [image1, image2, image3, image4, image5, image6, image7, image8,  image9, image10];
// export const ArchitecturalArray = [];

// const chunkSize = 3;
// const chunkImage = Math.floor(Architectural.length/chunkSize);

// for (let i = 0; i < Architectural.length; i += chunkImage) {
//     const chunk = Architectural.slice(i, i + chunkImage);
//     ArchitecturalArray.push(chunk);
//     // do whatever
// }
