import image1 from "../../../../src/assets/images/Catagories/KidsRoom/KidsRoom1.jpg";
import image2 from "../../../../src/assets/images/Catagories/KidsRoom/KidsRoom2.jpg";
import image3 from "../../../../src/assets/images/Catagories/KidsRoom/KidsRoom3.jpg";
import image4 from "../../../../src/assets/images/Catagories/KidsRoom/KidsRoom4.jpg";
import image5 from "../../../../src/assets/images/Catagories/KidsRoom/KidsRoom5.jpg";
import image6 from "../../../../src/assets/images/Catagories/KidsRoom/KidsRoom6.jpg";
import image7 from "../../../../src/assets/images/Catagories/KidsRoom/KidsRoom7.jpg";
import image8 from "../../../../src/assets/images/Catagories/KidsRoom/KidsRoom7.jpg";
import image9 from "../../../../src/assets/images/Catagories/KidsRoom/KidsRoom7.jpg";

const KidsRoom = [image1, image2, image3, image4, image5, image6, image7,image8,image9];
export const KidsRoomArray = [];

const chunkSize = 3;
const chunkImage = Math.floor(KidsRoom.length/chunkSize);

for (let i = 0; i < KidsRoom.length; i += chunkImage) {
    const chunk = KidsRoom.slice(i, i + chunkImage);
    KidsRoomArray.push(chunk);
}