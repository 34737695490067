import image1 from "../../../../src/assets/images/Catagories/CutSection/CutSection1.jpg";
import image2 from "../../../../src/assets/images/Catagories/CutSection/CutSection2.jpg";
import image3 from "../../../../src/assets/images/Catagories/CutSection/CutSection3.jpg";

const CutSection = [image1, image2, image3];
export const CutSectionArray = [];

const chunkSize = 3;
const chunkImage = Math.floor(CutSection.length/chunkSize);

for (let i = 0; i < CutSection.length; i += chunkImage) {
    const chunk = CutSection.slice(i, i + chunkImage);
    CutSectionArray.push(chunk);
}