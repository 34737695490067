import image1 from "../../../../src/assets/images/Catagories/OfficeAndShopDesign/OfficeAndShopDesign1.jpg";
import image2 from "../../../../src/assets/images/Catagories/OfficeAndShopDesign/OfficeAndShopDesign2.jpg";
import image3 from "../../../../src/assets/images/Catagories/OfficeAndShopDesign/OfficeAndShopDesign3.jpg";
import image4 from "../../../../src/assets/images/Catagories/OfficeAndShopDesign/OfficeAndShopDesign4.jpg";
import image5 from "../../../../src/assets/images/Catagories/OfficeAndShopDesign/OfficeAndShopDesign5.jpg";
import image6 from "../../../../src/assets/images/Catagories/OfficeAndShopDesign/OfficeAndShopDesign6.jpg";
import image7 from "../../../../src/assets/images/Catagories/OfficeAndShopDesign/OfficeAndShopDesign7.jpg";

import image8 from "../../../../src/assets/images/Catagories/OfficeAndShopDesign/OfficeAndShopDesign8.jpg";
import image9 from "../../../../src/assets/images/Catagories/OfficeAndShopDesign/OfficeAndShopDesign9.jpg";
import image10 from "../../../../src/assets/images/Catagories/OfficeAndShopDesign/OfficeAndShopDesign10.jpg";
import image11 from "../../../../src/assets/images/Catagories/OfficeAndShopDesign/OfficeAndShopDesign11.jpg";
import image12 from "../../../../src/assets/images/Catagories/OfficeAndShopDesign/OfficeAndShopDesign12.jpg";
import image13 from "../../../../src/assets/images/Catagories/OfficeAndShopDesign/OfficeAndShopDesign13.jpg";
import image14 from "../../../../src/assets/images/Catagories/OfficeAndShopDesign/OfficeAndShopDesign14.jpg";
import image15 from "../../../../src/assets/images/Catagories/OfficeAndShopDesign/OfficeAndShopDesign15.jpg";
import image16 from "../../../../src/assets/images/Catagories/OfficeAndShopDesign/OfficeAndShopDesign16.jpg";
import image17 from "../../../../src/assets/images/Catagories/OfficeAndShopDesign/OfficeAndShopDesign17.jpg";

import image18 from "../../../../src/assets/images/Catagories/OfficeAndShopDesign/OfficeAndShopDesign18.jpg";
import image19 from "../../../../src/assets/images/Catagories/OfficeAndShopDesign/OfficeAndShopDesign19.jpg";
import image20 from "../../../../src/assets/images/Catagories/OfficeAndShopDesign/OfficeAndShopDesign20.jpg";
import image21 from "../../../../src/assets/images/Catagories/OfficeAndShopDesign/OfficeAndShopDesign21.jpg";

const OfficeAndShopDesign = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12, image13, image14, 
    image15, image16, image17, image18, image19, image20, image21];
export const OfficeAndShopDesignArray = [];

const chunkSize = 3;
const chunkImage = Math.floor(OfficeAndShopDesign.length/chunkSize);

for (let i = 0; i < OfficeAndShopDesign.length; i += chunkImage) {
    const chunk = OfficeAndShopDesign.slice(i, i + chunkImage);
    OfficeAndShopDesignArray.push(chunk);
}
