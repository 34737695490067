import React, { useState, useRef, forwardRef } from 'react';
import emailjs from 'emailjs-com';
import { toast } from 'react-toast'
import {
    SuccessToast,
} from "../../utils/Helper";

const Inquiry = forwardRef((ref) => {
    const form = useRef();
    const wave = () => toast.success('Your mail has been sent to the admin. 👋')

    const onSubmitHandler = (e) => {
        e.preventDefault();
        window.scrollTo(0, 0);
        

        emailjs.sendForm('service_2kou72n', 'template_h2l1qdk', form.current, '9slMoKMwjvFJjrZko')
            .then((result) => {
                wave();
                console.log("result", result)
            }, (error) => {
                console.log(error.text);
            });

        e.target.reset();
    }

    // const changeHandler = (e, name) => {
    //     if (e.target) {    
    //       const { name } = e.target;
    //       setForm((prevState) => ({
    //         ...prevState,
    //         [name]: e.target.value,
    //       }));
    //     } else {
    //       const { value } = e;

    //       setForm((prevState) => ({
    //         ...prevState,
    //         [name]: value,
    //       }));
    //     }
    //   };

    return (
        <div id='contact-us' className='flex flex-col lg:flex-row lg:max-w-7xl max-w-screen-md mx-auto justify-around px-8 pt-32 pb-24'>
            <div className='w-full lg:w-2/5 pb-16 lg:p-0'>
                <h5 className='text-3xl font-bold'>Get in Touch with Us</h5>
                <div className=''>
                    <p className='text-base pt-3'><strong>TM Viz Studio</strong><br />
                        {/* Thaltej<br />
                        Ahmedabad&nbsp;<br />
                        India. */}
                        </p>
                </div>
                <p className='text-base pt-3'><strong>Phone:</strong>&nbsp;<a className='text-emerald-500' href="tel:+918401019771">+918401019771</a><br />
                    <strong>Service Inquiries:</strong>&nbsp;<a className='text-emerald-500' href="mailto:info@TM Viz Studio.com">info@TM Viz Studio.com</a><br />
                    {/* <strong>Job Inquiries: </strong><a href="mailto:hr@render-vision.com">hr@render-vision.com</a> */}
                    </p>
            </div>
            {/* <div ref={ref} className='w-full lg:w-2/5'> */}
            <div className='w-full lg:w-2/5'>
                <form ref={form} onSubmit={(e) => { onSubmitHandler(e) }}>
                    <div className="grid md:grid-cols-2 md:gap-6">
                        <div className="relative z-0 mb-6 w-full group">
                            <label htmlFor="">First Name</label>
                            <input type="text" name="first_name" id="floating_first_name" className="block py-2.5 px- w-full text-sm text-gray-900 bg-transparent border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder="Enter your first name " required />
                        </div>
                        <div className="relative z-0 mb-6 w-full group">
                            <label htmlFor="">Last Name</label>
                            <input type="text" name="last_name" id="floating_last_name" className="block py-2.5 px- w-full text-sm text-gray-900 bg-transparent border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder="Enter last name " required />
                        </div>
                    </div>
                    <div className="grid md:grid-cols-2 md:gap-6">
                        <div className="relative z-0 mb-6 w-full group">
                            <label htmlFor="">Email</label>
                            <input type="email" name="email" id="email" className="block py-2.5 px- w-full text-sm text-gray-900 bg-transparent border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder="email" required />
                        </div>
                        <div className="relative z-0 mb-6 w-full group">
                            <label htmlFor="">Phone Number</label>
                            <input type="number" name="phone_number" id="phone_number" className="block py-2.5 px- w-full text-sm text-gray-900 bg-transparent border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder="Phone Number" required />
                        </div>
                    </div>
                    <div className="relative z-0 mb-6 w-full group">
                        <label htmlFor="">Company</label>
                        <input type="text" name="company" id="company" className="block py-2.5 px- w-full text-sm text-gray-900 bg-transparent border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder="Company" required />
                    </div>
                    <div className="relative z-0 mb-6 w-full group">
                        <label htmlFor="">Message</label>
                        <textarea row="10" col="40" name="message" id="Message" className="block py-2.5 px- w-full text-sm text-gray-900 bg-transparent border-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder="Enter your message" required />
                    </div>
                    <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button>
                </form>
            </div>

        </div>
    )
})

export default Inquiry