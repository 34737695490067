import React, { lazy, memo, useEffect, useState, useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Architecturalvisualiztion from "../component/Sevices/Architecturalvisualiztion";
import Header from "../component/layout/Header";
import Footer from "../component/layout/Footer";
import { Loader } from "../component/commonComponent/main";
import Inquiry from "../component/layout/Inquiry";
import Interiorvisualization from "../component/Sevices/Interiorvisualization";
import Animation from "../component/Sevices/3dAnimation";
import Aboutus from "../component/Sevices/Aboutus";
const Dashboard = lazy(() => import("../container/Dashboard/Dashboard"));

const Index = memo(() => {
    const footerRef = useRef(null);
    const [loader, setLoader] = useState(true);
    const [clearLoaderTimeout, setClearLoaderTimeout] = useState(0);

    useEffect(() => {
        clearTimeout(clearLoaderTimeout);
        var clearLoaderTimer = setTimeout(() => {
            setLoader(false);
        }, 6000);
        setClearLoaderTimeout(clearLoaderTimer);
    }, []);
    return (
        <>
          {loader ? <Loader/> :
          <Router>
            <Header />
            <Routes>
                <Route exact path="/" element={<Dashboard />} />
                <Route path="/architecturalvisualiztion" element={<Architecturalvisualiztion />} />
                <Route path="/interiorvisualization" element={<Interiorvisualization />} />
                <Route path="/animation" element={<Animation />} />
                <Route path="/aboutus" element={<Aboutus />} />
            </Routes>
            <Footer />
            
        </Router>}
        </>
    );
});

export default Index;