import React, { useEffect } from "react";
import AnimatedText from 'react-animated-text-content';
import $ from "jquery";

const Loader = () => {

    useEffect(() => {
        wordflick();
    }, []);

    const wordflick = () => {
        var words = ['WHERE IDEAS MEET SKILLS'],
            part,
            i = 0,
            offset = 0,
            len = words.length,
            forwards = true,
            skip_count = 0,
            skip_delay = 15,
            speed = 70;

        setInterval(function () {
            if (forwards) {
                if (offset >= words[i].length) {
                    ++skip_count;
                    if (skip_count == skip_delay) {
                        forwards = false;
                        skip_count = 0;
                    }
                }
            }
            else {
                if (offset == 0) {
                    forwards = true;
                    i++;
                    offset = 0;
                    if (i >= len) {
                        i = 0;
                    }
                }
            }
            part = words[i].substr(0, offset);
            if (skip_count == 0) {
                if (forwards) {
                    offset++;
                }
                else {
                    offset--;
                }
            }
            $('.word').text(part);
        }, speed);
    }

    return (
        <>
            <div className="container mx-auto">
                <div className="absolute inset-1/2 flex justify-center items-center">
                    <div className="word"></div>
                </div>
            </div>

        </>
    );
}

export default Loader;