import React from "react";
import { FaPhone } from 'react-icons/fa';
import { FaEnvelope } from 'react-icons/fa';
import website_icon from "../../assets/images/icon/TM-ViZ-STUDIO.png";

const Footer = ({footerRef}) => {

    const redirectToContactUs =(e)=>{
        e.preventDefault();
        footerRef.current.scrollIntoView({ behavior: "smooth" });
    }
    return (
        <>
            <footer className="">
                <div className=" border-0 border-gray-500 border-b">
                    <div className="max-w-7xl mx-auto flex flex-col  lg:flex-row justify-center lg:justify-between items-center border-slate-white py-6 lg:px-4">
                        <div className="footer_left">
                            <div className="logo pb-4 lg:pb-0">
                                <a href="/" >
                                    <img
                                        src={website_icon}
                                        className="bg-white text-2xl font-bold text-white icon-image w-[70px] h-[70px]"
                                        alt="..."
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="footer_menu-container">
                            <ul id="" className=" flex flex-wrap md:pb-2 justify-center text-center items-center md:justify-around">
                                <li id="menu-item-4742" className="text-white pb-2 md:pb-0"><a href="#intro" className="border-r-2 text-sm px-3">Portfolio</a></li>
                                <li id="menu-item-4743" className="text-white pb-2 md:pb-0"><a href="#workFLow" className="border-r-2 text-sm px-3" border-0>Workflow</a></li>
                                <li  id="menu-item-4748" className="text-white pb-2 md:pb-0"><a href="#contact-us" className=" text-sm px-3" border-0>Contact us</a></li>
                                {/* <li id="menu-item-4749" className="text-white pb-2 md:pb-0"><a href="/" className="border-r-2 text-sm px-3" border-0>Impressum</a></li> */}
                            </ul>						</div>
                    </div>
                </div>
                <div className="max-w-xs md:max-w-7xl mx-auto text-center md:text-start flex flex-col md:flex-row md:items-center md:justify-between xl:px-0 px-6 py-12 ">
                    <div className=" hidden lg:inline">
                        <div>
                            <div className="pb-2">
                                <a href="" className="no-underline flex items-center curser-pointer text-slate-400 text-sm">
                                    <span className="pr-2"><FaPhone/></span>
                                    {/* <p>+91 8401019771</p> */}
                                    <a href="tel:+918401019771">+91 8401019771</a>
                                </a>
                            </div>
                            <div className="pb-2">
                                <a href="" className="no-underline flex items-center curser-pointer text-slate-400 text-sm">
                                    <span className="pr-2"><FaEnvelope/></span>
                                    <a href="mailto:tmvizstudio@gmail.com">tmvizstudio@gmail.com</a>
                                    {/* <p>tmvizstudio@gmail.com</p> */}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col services">
                        <ul>
                            <li className="pb-4">
                                <a href="" className="no-underline text-white font-bold">Services</a>
                            </li>
                            <li className="pb-2">
                                <a href="" className="no-underline text-slate-400 text-sm">3D Rendering</a>
                            </li>
                            {/* <li className="pb-2">
                                <a href="" className="no-underline text-slate-400 text-sm">3D Product Rendering</a>
                            </li> */}
                            <li className="pb-2">
                                <a href="" className="no-underline text-slate-400 text-sm">360 Degree Tour</a>
                            </li>
                            <li className="pb-2">
                                <a href="" className="no-underline text-slate-400 text-sm">Virtual Reality</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col services">
                        <ul>
                            <li className="pb-2">
                                <a href="" className="no-underline text-slate-400 text-sm"> </a>
                            </li>
                            <li className="pb-2">
                                <a href="" className="no-underline text-slate-400 text-sm">Architectural Visualization</a>
                            </li>
                            <li className="pb-2">
                                <a href="" className="no-underline text-slate-400 text-sm">3d Renderings for Commercial Real Estate</a>
                            </li>
                           
                        </ul>
                    </div>
                    <div className="col services">
                        <ul>
                            <li className="pb-2">
                                <a href="" className="no-underline text-slate-400 text-sm"> </a>
                            </li>
                            <li className="pb-2">
                                <a href="" className="no-underline text-slate-400 text-sm">Interior Visualization</a>
                            </li>
                            <li className="pb-2">
                                <a href="" className="no-underline text-slate-400 text-sm">3D Animation</a>
                            </li>
                            {/* <li className="pb-2">
                                <a href="" className="no-underline text-slate-400 text-sm">Apartment selector</a>
                            </li> */}
                        </ul>
                    </div>
                </div>
                <div className="flex justify-center items-center">
                    <div className=" lg:hidden ">
                        <ul>
                            <li className="pb-2">
                                <a href="" className="no-underline flex items-center p text-slate-400 text-sm">
                                    <span className="pr-2"><FaPhone/></span>
                                    {/* <p>+1 347-758-4912</p> */}
                                    <a href="tel:+918401019771">+918401019771</a>
                                </a>
                            </li>
                            <li className="pb-2">
                                <a href="" className="no-underline flex items-center p text-slate-400 text-sm">
                                    <span className="pr-2"><FaEnvelope/></span>
                                    {/* <p>partner@render-vision.com</p> */}
                                    <a href="mailto:tmvizstudio@gmail.com">tmvizstudio@gmail.com</a>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <span className="block text-sm text-gray-500 mx-auto dark:text-gray-400 text-center lg:pt-0 pt-12 pb-12">© <a className="hover:underline">TM Viz Studio</a>. All Rights Reserved.
                </span>
            </footer>
        </>
    );
}

export default Footer;