import image1 from "../../../../src/assets/images/Catagories/Bedroom/bedroom1.jpg";
import image2 from "../../../../src/assets/images/Catagories/Bedroom/bedroom2.jpg";
import image3 from "../../../../src/assets/images/Catagories/Bedroom/bedroom3.jpg";
import image4 from "../../../../src/assets/images/Catagories/Bedroom/bedroom4.jpg";
import image5 from "../../../../src/assets/images/Catagories/Bedroom/bedroom5.jpg";
import image6 from "../../../../src/assets/images/Catagories/Bedroom/bedroom6.jpg";
import image7 from "../../../../src/assets/images/Catagories/Bedroom/bedroom7.jpg";
import image8 from "../../../../src/assets/images/Catagories/Bedroom/bedroom8.jpg";

import image9 from "../../../../src/assets/images/Catagories/Bedroom/bedroom9.jpg";
import image10 from "../../../../src/assets/images/Catagories/Bedroom/bedroom10.jpg";
import image11 from "../../../../src/assets/images/Catagories/Bedroom/bedroom11.jpg";
import image12 from "../../../../src/assets/images/Catagories/Bedroom/bedroom12.jpg";
import image13 from "../../../../src/assets/images/Catagories/Bedroom/bedroom13.jpg";

import image14 from "../../../../src/assets/images/Catagories/Bedroom/bedroom14.jpg";
import image15 from "../../../../src/assets/images/Catagories/Bedroom/bedroom15.jpg";
import image16 from "../../../../src/assets/images/Catagories/Bedroom/bedroom16.jpg";
import image17 from "../../../../src/assets/images/Catagories/Bedroom/bedroom17.jpg";
import image18 from "../../../../src/assets/images/Catagories/Bedroom/bedroom18.jpg";
import image19 from "../../../../src/assets/images/Catagories/Bedroom/bedroom19.jpg";
import image20 from "../../../../src/assets/images/Catagories/Bedroom/bedroom20.jpg";
import image21 from "../../../../src/assets/images/Catagories/Bedroom/bedroom21.jpg";

import image23 from "../../../../src/assets/images/Catagories/Bedroom/bedroom22.jpg";
import image24 from "../../../../src/assets/images/Catagories/Bedroom/bedroom23.jpg";
import image25 from "../../../../src/assets/images/Catagories/Bedroom/bedroom24.jpg";
import image26 from "../../../../src/assets/images/Catagories/Bedroom/bedroom25.jpg";
import image27 from "../../../../src/assets/images/Catagories/Bedroom/bedroom26.jpg";
import image28 from "../../../../src/assets/images/Catagories/Bedroom/bedroom27.jpg";

const BedRoom = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12, image13, image14, 
    image15, image16, image17, image18, image19, image20, image21, image23, image24, image25, image26, image27, image28];
export const BedRoomArray = [];

const chunkSize = 3;
const chunkImage = Math.floor(BedRoom.length/chunkSize);

for (let i = 0; i < BedRoom.length; i += chunkImage) {
    const chunk = BedRoom.slice(i, i + chunkImage);
    BedRoomArray.push(chunk);
}
