import React from 'react'
import con1 from '../../assets/images/icon/as_1.svg'
import con2 from '../../assets/images/icon/as_2.svg'
import con3 from '../../assets/images/icon/as_3.svg'
import con4 from '../../assets/images/icon/as_4.svg'
import con5 from '../../assets/images/icon/as_5.svg'
import con6 from '../../assets/images/icon/as_6.svg'
import con7 from '../../assets/images/icon/as_7.svg'
import con8 from '../../assets/images/icon/as_8.svg'
import con9 from '../../assets/images/icon/as_9.svg'

const Aboutus = () => {
    return (
        <>
            <div className='about-wrapper '>
                <div className="about-content py-32 px-3">
                    <h2 className='max-w-7xl mx-auto max-w-7xlmd:text-5xl text-white text-center text-4xl font-bold px-4 mb-9'>About us</h2>
                    <p className='max-w-7xl mx-auto text-center max-w-4xl text-white mx-auto text-xl text-gray-500 font-medium'>Rely on our know-how and convince your customers with impressive,
                        realistic 3d-visualization projects from TMVizStudio.</p>
                </div>
            </div>
            <div className="content max-w-7xl mx-auto my-16">
                <h2 className='md:text-5xl text-center text-4xl font-bold px-4 mb-9 after:content-[""] after:h-[2px] after:bg-orange-500 after:my-10 after:mx-auto after:w-[100px] after:block'>Why we are your perfect partner</h2>

                <div className='lg:flex items-start justify-between px-3'>
                    <p className=' text-left max-w-4xl mx-auto text-base lg:w-2/6 px-2 text-gray-500'>TMVizStudio is the culmination of the vision of one man who recognized the potential of <a className='text-orange-500'>3D visualization</a> early on. Years ago, Michael Falk, owner and founder, discovered 3D visualisation and made it his vocation.
                        <br /><br />
                        Today, TMVizStudio is a <a className='text-orange-500'>3D agency</a> that embodies quality and outstanding service.
                        <br /><br />
                        Our young and dynamic team enables our clients to have an exciting work experience. Not only do we deliver<a className='text-orange-500'> 3D renderings</a> as a pure service provider but we also create images of the inner visions and dreams of our clients. We interweave impressions and associations with the help of various settings to achieve a unique emotional depth that cannot be expressed with a photo.</p>

                    <p className=' text-left max-w-4xl mx-auto text-base lg:w-2/6 px-2 text-gray-500'>We do not see ourselves as a corporate entity but as a partner. We help our clients to depict their dreams photo-realistically and to develop a better sense of their projects. Finding out exactly what our clients want and then realising it is the core aim of our work. The amazement that is unconsciously reflected in our clients’ eyes is a true reward.
                        <br /><br />
                        However, TMVizStudio does not only stand for a close client experience, but also for the highest quality. We live up to our quality standards through constant development and optimisation of our skills. We see ourselves in an industry that is subject to constant change and technological progress.</p>

                    <p className=' text-left max-w-4xl mx-auto text-base lg:w-2/6 px-2 text-gray-500'>We embrace this progress with our flexibility to continuously challenging work processes and adapting them to changing circumstances.
                        <br /><br />
                        In doing so, we enable our <a className='text-orange-500'>3D artists</a>, the real dream creators of our team, to incorporate their personal touch into every visualisation. This “personal signature” boosts every image to the top and leaves nothing more to be desired in terms of its uniqueness.
                        <br /><br />
                        For us, client satisfaction is our top priority. We fulfil this demand by dedicating ourselves individually to each project and bringing out the best for the client. We are ready to break new ground and offer unlimited possibilities to our clients.</p>
                </div>
            </div>
            <div className='bg-gray-100'>
                <div className="content max-w-7xl mx-auto py-16 px-3">
                    <h2 className='md:text-5xl text-center text-4xl font-bold px-4 mb-9 after:content-[""] after:h-[2px] after:bg-orange-500 after:my-10 after:mx-auto after:w-[100px] after:block'>Your advantages</h2>
                </div>
                <div className="md:flex justify-between items-center px-4 pb-20">
                    <div className='md:w-2/6 md:px-6'>
                        <h2 className='text-2xl text-center font-bold'>Services</h2>
                        <div className="relative flex flex-col justify-center items-center">
                            <div className=''>
                                <img width="43" height="61" className='mt-16 mb-3' src={con1} alt="" />
                            </div>
                            <p>Our project managers make the implementation of your project as easy as possible, as they will be highly-professional contact people who perfectly facilitate the communication between you and our 3D-artists.</p>
                        </div>
                        <div className="relative flex flex-col justify-center items-center">
                            <div className=''>
                                <img width="43" height="61" className=' mt-16 mb-3' src={con2} alt="" />
                            </div>
                            <p>Our project managers make the implementation of your project as easy as possible, as they will be highly-professional contact people who perfectly facilitate the communication between you and our 3D-artists.</p>
                        </div>
                        <div className="relative flex flex-col justify-center items-center">
                            <div className=''>
                                <img width="43" height="61" className=' mt-16 mb-3' src={con3} alt="" />
                            </div>
                            <p>Our project managers make the implementation of your project as easy as possible, as they will be highly-professional contact people who perfectly facilitate the communication between you and our 3D-artists.</p>
                        </div>
                    </div>
                    <div className='md:w-2/6 md:px-6'>
                    <h2 className='text-2xl text-center font-bold'>Quality</h2>
                        <div className="relative flex flex-col justify-center items-center">
                            <div className=''>
                                <img width="43" height="61" className=' mt-16 mb-3' src={con4} alt="" />
                            </div>
                            <p>Our project managers make the implementation of your project as easy as possible, as they will be highly-professional contact people who perfectly facilitate the communication between you and our 3D-artists.</p>
                        </div>
                        <div className="relative flex flex-col justify-center items-center">
                            <div className=''>
                                <img width="43" height="61" className=' mt-16 mb-3' src={con5} alt="" />
                            </div>
                            <p>Our project managers make the implementation of your project as easy as possible, as they will be highly-professional contact people who perfectly facilitate the communication between you and our 3D-artists.</p>
                        </div>
                        <div className="relative flex flex-col justify-center items-center">
                            <div className=''>
                                <img width="43" height="61" className=' mt-16 mb-3' src={con6} alt="" />
                            </div>
                            <p>Our project managers make the implementation of your project as easy as possible, as they will be highly-professional contact people who perfectly facilitate the communication between you and our 3D-artists.</p>
                        </div>
                    </div>
                    <div className='md:w-2/6 md:px-6'>
                    <h2 className='text-2xl text-center font-bold'>Experience</h2>
                        <div className="relative flex flex-col justify-center items-center">
                            <div className=''>
                                <img width="43" height="61" className=' mt-16 mb-3' src={con7} alt="" />
                            </div>
                            <p>Our project managers make the implementation of your project as easy as possible, as they will be highly-professional contact people who perfectly facilitate the communication between you and our 3D-artists.</p>
                        </div>
                        <div className="relative flex flex-col justify-center items-center">
                            <div className=''>
                                <img width="43" height="61" className=' mt-16 mb-3' src={con8} alt="" />
                            </div>
                            <p>Our project managers make the implementation of your project as easy as possible, as they will be highly-professional contact people who perfectly facilitate the communication between you and our 3D-artists.</p>
                        </div>
                        <div className="relative flex flex-col justify-center items-center">
                            <div className=''>
                                <img width="43" height="61" className=' mt-16 mb-3' src={con9} alt="" />
                            </div>
                            <p>Our project managers make the implementation of your project as easy as possible, as they will be highly-professional contact people who perfectly facilitate the communication between you and our 3D-artists.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}

export default Aboutus
