import image1 from "../../../../src/assets/images/Catagories/DiningAndKitchen/DiningAndKitchen1.jpg";
import image2 from "../../../../src/assets/images/Catagories/DiningAndKitchen/DiningAndKitchen2.jpg";
import image3 from "../../../../src/assets/images/Catagories/DiningAndKitchen/DiningAndKitchen3.jpg";
import image4 from "../../../../src/assets/images/Catagories/DiningAndKitchen/DiningAndKitchen4.jpg";
import image5 from "../../../../src/assets/images/Catagories/DiningAndKitchen/DiningAndKitchen5.jpg";
import image6 from "../../../../src/assets/images/Catagories/DiningAndKitchen/DiningAndKitchen6.jpg";
import image7 from "../../../../src/assets/images/Catagories/DiningAndKitchen/DiningAndKitchen7.jpg";

import image8 from "../../../../src/assets/images/Catagories/DiningAndKitchen/DiningAndKitchen8.jpg";
import image9 from "../../../../src/assets/images/Catagories/DiningAndKitchen/DiningAndKitchen9.jpg";
import image10 from "../../../../src/assets/images/Catagories/DiningAndKitchen/DiningAndKitchen10.jpg";
import image11 from "../../../../src/assets/images/Catagories/DiningAndKitchen/DiningAndKitchen11.jpg";
import image12 from "../../../../src/assets/images/Catagories/DiningAndKitchen/DiningAndKitchen12.jpg";
import image13 from "../../../../src/assets/images/Catagories/DiningAndKitchen/DiningAndKitchen13.jpg";
import image14 from "../../../../src/assets/images/Catagories/DiningAndKitchen/DiningAndKitchen14.jpg";
import image15 from "../../../../src/assets/images/Catagories/DiningAndKitchen/DiningAndKitchen14.jpg";

const DiningAndKitchen = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image10, image11, image12, image13, image14, image15];
export const DiningAndKitchenArray = [];

const chunkSize = 3;
const chunkImage = Math.floor(DiningAndKitchen.length/chunkSize);

for (let i = 0; i < DiningAndKitchen.length; i += chunkImage) {
    const chunk = DiningAndKitchen.slice(i, i + chunkImage);
    DiningAndKitchenArray.push(chunk);
}